import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { getAuth, setPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, browserSessionPersistence } from "firebase/auth";

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const auth = getAuth();

    // Check if the user is already authenticated on mount
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      }
    });
  }, [setIsAuthenticated]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (document.activeElement.name === 'Login') {
      try {
        // Set persistence to session only
        await setPersistence(auth, browserSessionPersistence);
        await signInWithEmailAndPassword(auth, email, password);

        Swal.fire({
          icon: 'success',
          title: 'Successfully logged in!',
          showConfirmButton: false,
          timer: 1500,
        });

        setIsAuthenticated(true);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Incorrect email or password.',
          showConfirmButton: true,
        });
      }
    } else if (document.activeElement.name === 'Register') {
      try {
        // Set persistence to session only for registration as well
        await setPersistence(auth, browserSessionPersistence);
        await createUserWithEmailAndPassword(auth, email, password);

        Swal.fire({
          icon: 'success',
          title: 'Successfully registered and logged in!',
          showConfirmButton: false,
          timer: 1500,
        });

        setIsAuthenticated(true);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Registration failed',
          text: error.message,
          showConfirmButton: true,
        });
      }
    }
  };

  return (
    <div>
      <style jsx="true">{`
        .login-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #CBC3E3;
        }

        .login-form {
          background-color: #ffffff;
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 400px;
          text-align: center;
        }

        .login-form h1 {
          margin-bottom: 20px;
          font-size: 24px;
        }

        .login-form label {
          display: block;
          text-align: left;
          margin-bottom: 8px;
          font-weight: bold;
        }

        .login-form input[type="email"],
        .login-form input[type="password"] {
          width: 100%;
          padding: 10px;
          margin-bottom: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
          box-sizing: border-box;
        }

        .login-form input[type="submit"] {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          background-color: #301934;
          color: white;
          font-size: 16px;
          cursor: pointer;
        }

        .button-container {
          display: flex;
          justify-content: center;
        }

        .login-form .register-btn {
          background-color: #301934;
        }

        @media (max-width: 600px) {
          .login-form {
            padding: 20px;
          }
        }
      `}</style>

      <div className="login-container">
        <form onSubmit={handleLogin} className="login-form">
          <h1>Game It Up - (AMS)</h1>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="admin@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            placeholder="******"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="button-container">
            <input type="submit" value="Login" name="Login" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
