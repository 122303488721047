import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import necessary components for routing

import Login from '../Login';
import Dashboard from '../Dashboard';
//import GameHistory from '../GameHistory'; // Adjust the path as necessary
import ViewEmployee from '../Dashboard/View';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    setIsAuthenticated(JSON.parse(localStorage.getItem('is_authenticated')));
  }, []);

  return (
    <Router>
      <>
        <Routes>
          {/* Define the Game History route (accessible without authentication) */}
          <Route path="/game-history" element={<ViewEmployee />} />

          {isAuthenticated ? (
            <>
              {/* Define the Dashboard route */}
              <Route path="/" element={<Dashboard setIsAuthenticated={setIsAuthenticated} />} />
              
            </>
          ) : (
            <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          )}
        </Routes>
      </>
    </Router>
  );
};

export default App;
