import React from 'react';
import Swal from 'sweetalert2';
import { getAuth, signOut } from "firebase/auth";

const Logout = ({ setIsAuthenticated }) => {
  const handleLogout = () => {
    Swal.fire({
      icon: 'question',
      title: 'Logging Out',
      text: 'Are you sure you want to log out?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        const auth = getAuth();
        signOut(auth)
          .then(() => {
            // Clear the stored token from localStorage
            localStorage.removeItem('authToken');
            setIsAuthenticated(false);

            Swal.fire({
              timer: 1500,
              showConfirmButton: false,
              willOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                Swal.fire({
                  icon: 'success',
                  title: 'Logged out successfully!',
                  showConfirmButton: false,
                  timer: 1500,
                });
              },
            });
          })
          .catch((error) => {
            console.error("Logout failed:", error);
            Swal.fire({
              icon: 'error',
              title: 'Logout Failed',
              text: 'Something went wrong. Please try again.',
              showConfirmButton: true,
            });
          });
      }
    });
  };

  return (
    <button
      style={{ marginLeft: '12px' }}
      className="muted-button"
      onClick={handleLogout}
    >
      Logout
    </button>
  );
};

export default Logout;
