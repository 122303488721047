import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FaCashRegister, FaUserAlt } from 'react-icons/fa';

const Table = ({ employees, handleEdit, handleDelete }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [minSalary, setMinSalary] = useState('');
  const [maxSalary, setMaxSalary] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [customId, setCustomId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showChartModal, setShowChartModal] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [enteredPin, setEnteredPin] = useState('');
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [groupBy, setGroupBy] = useState('month');

  const employeesPerPage = 5;
  const correctPin = '1551';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GHS',
    minimumFractionDigits: null,
  });

  const handlePinSubmit = () => {
    if (enteredPin === correctPin) {
      setIsPinVerified(true);
      setShowPinModal(false);
      if (showChartModal) {
        setShowChartModal(true);
      }
    } else {
      alert('Incorrect PIN. Please try again.');
    }
  };

  const filteredEmployees = employees
    ? employees.filter(employee => {
        const employeeSalary = Number(employee.totalSpent);
        const employeeDate = new Date(employee.date);
        const withinSalaryRange =
          (!minSalary || employeeSalary >= Number(minSalary)) &&
          (!maxSalary || employeeSalary <= Number(maxSalary));
        const withinDateRange =
          (!startDate || employeeDate >= new Date(startDate)) &&
          (!endDate || employeeDate <= new Date(endDate));
        const matchesSearchTerm =
          employee.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          employee.lastName.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCustomId =
          !customId || employee.id.toString().includes(customId);

        return matchesSearchTerm && withinSalaryRange && withinDateRange && matchesCustomId;
      })
    : [];

  const sortedEmployees = filteredEmployees.sort((a, b) => new Date(b.date) - new Date(a.date));

  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = sortedEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

  const totalSpentInRange = filteredEmployees.reduce((sum, employee) => sum + Number(employee.totalSpent), 0);
  const totalSalary = filteredEmployees.reduce((sum, employee) => sum + Number(employee.totalSpent), 0);
  const customerCount = filteredEmployees.length;
  const totalPages = Math.ceil(filteredEmployees.length / employeesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const groupSalaries = (employees, groupBy) => {
    const salaryData = employees.reduce((acc, employee) => {
      const date = new Date(employee.date);
      const key = groupBy === 'day'
        ? date.toLocaleDateString('default', { day: 'numeric', month: 'short', year: 'numeric' })
        : date.toLocaleString('default', { month: 'short', year: 'numeric' });

      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += Number(employee.totalSpent);
      return acc;
    }, {});

    const sortedSalaryData = Object.entries(salaryData).sort((a, b) => {
      const dateA = new Date(a[0]);
      const dateB = new Date(b[0]);
      return dateA - dateB;
    });

    return sortedSalaryData.map(([date, salary]) => ({
      date,
      salary,
    }));
  };

  const salaryChartData = groupSalaries(filteredEmployees, groupBy);

  return (
    <div className="contain-table">
      <div className="statistics-box">
        <div className="stat-item stat-blue">
          <FaCashRegister size={24} />
          <h3>Total Pay</h3>
          {isPinVerified ? (
            <p>{formatter.format(totalSalary)}</p>
          ) : (
            <button onClick={() => setShowPinModal(true)} className="button chart-button">
              Enter PIN to view
            </button>
          )}
        </div>

        <div className="stat-item stat-orange">
          <FaUserAlt size={24} />
          <h3>Customer Count</h3>
          <p>{customerCount}</p>
        </div>

        <div className="stat-item">
          <button onClick={() => {
            if (isPinVerified) {
              setShowChartModal(true);
            } else {
              setShowPinModal(true);
            }
          }} className="button chart-button">
            View Sales
          </button>
        </div>
      </div>

      <div className="filter-container">
        <div className="input-container">
          <input type="number" placeholder="Min Sale" value={minSalary} onChange={(e) => setMinSalary(e.target.value)} className="filter-input" />
          <input type="number" placeholder="Max Sale" value={maxSalary} onChange={(e) => setMaxSalary(e.target.value)} className="filter-input" />
          <input type="date" placeholder="Start Date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="filter-input" />
          <input type="date" placeholder="End Date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="filter-input" />
          <input type="text" placeholder="Filter by ID" value={customId} onChange={(e) => setCustomId(e.target.value)} className="filter-input" />
          <button onClick={() => setCurrentPage(1)} className="button filter-button">Apply Filters</button>
        </div>
      </div>

      {startDate && endDate && (
        <div className="total-spent-box">
          <h4>Total Spent by All Customers:</h4>
          <p>{formatter.format(totalSpentInRange)}</p>
        </div>
      )}

      <table className="striped-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Total Due</th>
            <th>Updated Date</th>
            <th colSpan={2} className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentEmployees.length > 0 ? (
            currentEmployees.map((employee) => (
              <tr key={employee.id}>
                <td>{employee.id}</td>
                <td>{employee.firstName}</td>
                <td>
                  {isPinVerified ? (
                    formatter.format(employee.totalSpent)
                  ) : (
                    '*****'
                  )}
                </td>
                <td>{new Date(employee.date).toLocaleString()}</td>
                <td>
                  <button onClick={() => handleEdit(employee.id)} className="button muted-button">Update</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10">No Customer Data</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
            {index + 1}
          </button>
        ))}
      </div>

      {showChartModal && (
        <div className="modal-overlay" onClick={() => setShowChartModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={() => setShowChartModal(false)}>&times;</button>
            <h3>Sales Distribution by {groupBy === 'day' ? 'Day' : 'Month'}</h3>
            <div className="toggle-group">
              <button onClick={() => setGroupBy('day')} className={groupBy === 'day' ? 'active' : ''}>By Day</button>
              <button onClick={() => setGroupBy('month')} className={groupBy === 'month' ? 'active' : ''}>By Month</button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={salaryChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="salary" stroke="#007bff" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}

      {showPinModal && (
        <div className="modal-overlay" onClick={() => setShowPinModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={() => setShowPinModal(false)}>&times;</button>
            <h3>Enter PIN</h3>
            <input type="password" value={enteredPin} onChange={(e) => setEnteredPin(e.target.value)} placeholder="Enter your PIN" />
            <button onClick={handlePinSubmit} className="button">Submit</button>
          </div>
        </div>
      )}

      <style jsx>{`
        .contain-table {
          margin: 20px;
        }
        .statistics-box {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: space-between;
        }
        .stat-item {
          padding: 20px;
          border-radius: 8px;
          color: white;
          display: flex;
          align-items: center;
          gap: 10px;
          width: 45%;
        }
        .stat-blue {
          background-color: #007bff;
        }
        .stat-orange {
          background-color: #ff7b2f;
        }
        .filter-container {
          margin: 20px 0;
        }
        .input-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          align-items: center;
        }
        .filter-input {
          padding: 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
          flex: 1;
        }
        .filter-button {
          padding: 8px 12px;
          border: none;
          background-color: #007bff;
          color: white;
          border-radius: 4px;
          cursor: pointer;
        }
        .total-spent-box {
          margin: 10px 0;
          font-weight: bold;
        }
        .striped-table {
          width: 100%;
          border-collapse: collapse;
        }
        .striped-table th,
        .striped-table td {
          padding: 12px;
          border: 1px solid #ddd;
          text-align: center;
        }
        .striped-table tr:nth-child(even) {
          background-color: #f9f9f9;
        }
        .pagination {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          gap: 5px;
        }
        .pagination button {
          padding: 5px 10px;
          border: none;
          background-color: #007bff;
          color: white;
          cursor: pointer;
          border-radius: 4px;
        }
        .pagination button.active {
          background-color: #0056b3;
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          width: 300px;
          text-align: center;
        }
        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          background: none;
          border: none;
          cursor: pointer;
        }
          .muted-button{
          background-color:blue;
          color:white;
          }
        .toggle-group {
          margin-top: 10px;
          display: flex;
          justify-content: center;
        }
        .toggle-group button {
          padding: 8px;
          margin: 0 5px;
          cursor: pointer;
          border: 1px solid #007bff;
          border-radius: 4px;
        }
        .toggle-group button.active {
          background-color: #007bff;
          color: white;
        }
      `}</style>
    </div>
  );
};

export default Table;
