import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../src/config/firestore';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Edit = ({ employees, selectedEmployee, setEmployees, setIsEditing, getEmployees }) => {
  const id = selectedEmployee.id;

  const [firstName] = useState(selectedEmployee.firstName || '');
  const [date, setDate] = useState(selectedEmployee.date || '');
  const [totalSpent, setTotalSpent] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [editHistory, setEditHistory] = useState([]);

  const [gameValues, setGameValues] = useState({
    videoGames: selectedEmployee.videoGames || 0,
    socks: selectedEmployee.socks || 0,
    basketball: selectedEmployee.basketball || 0,
    connectball: selectedEmployee.connectball || 0,
    jumpIt: selectedEmployee.jumpIt || 0,
    hammer: selectedEmployee.hammer || 0,
    hitBeans: selectedEmployee.hitBeans || 0,
    justDance: selectedEmployee.justDance || 0,
    clawMachine: selectedEmployee.clawMachine || 0,
    racing: selectedEmployee.racing || 0,
    aliens: selectedEmployee.aliens || 0,
    vr: selectedEmployee.vr || 0,
    f1: selectedEmployee.f1 || 0,
    snowcross: selectedEmployee.snowcross || 0,
    karaoke: selectedEmployee.karaoke || 0,
    jurassicPark: selectedEmployee.jurassicPark || 0,
    laserTag: selectedEmployee.laserTag || 0,
    footballSnooker: selectedEmployee.footballSnooker || 0,
    goKart: selectedEmployee.goKart || 0,
  });

  const gameFields = [
    { label: 'Video Games', key: 'videoGames', increment: 10 },
    { label: 'Socks', key: 'socks', increment: 10 },
    { label: 'Basketball', key: 'basketball', increment: 10 },
    { label: 'ConnectBall', key: 'connectball', increment: 10 },
    { label: 'Jump It', key: 'jumpIt', increment: 10 },
    { label: 'Hammer', key: 'hammer', increment: 15 },
    { label: 'Hit Beans', key: 'hitBeans', increment: 20 },
    { label: 'Just Dance', key: 'justDance', increment: 20 },
    { label: 'Claw Machine', key: 'clawMachine', increment: 20 },
    { label: 'Racing', key: 'racing', increment: 20 },
    { label: 'Aliens', key: 'aliens', increment: 30 },
    { label: 'VR', key: 'vr', increment: 30 },
    { label: 'F1', key: 'f1', increment: 30 },
    { label: 'Snowcross', key: 'snowcross', increment: 40 },
    { label: 'Karaoke', key: 'karaoke', increment: 40 },
    { label: 'Jurassic Park', key: 'jurassicPark', increment: 50 },
    { label: 'Laser Tag', key: 'laserTag', increment: 60 },
    { label: 'Football Snooker', key: 'footballSnooker', increment: 50 },
    { label: 'Go Kart', key: 'goKart', increment: 50 },
  ];

  useEffect(() => {
    setTotalSpent(
      Object.values(gameValues).reduce((sum, value) => sum + Number(value), 0)
    );
  }, [gameValues]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!firstName || !date) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required, including the date.',
        showConfirmButton: true,
      });
    }

    const updatedEmployee = {
      id,
      firstName,
      totalSpent,
      date,
      ...gameValues,
    };

    try {
      await setDoc(doc(db, 'employees', id), updatedEmployee);
      const now = new Date();
      setEditHistory((prev) => [
        ...prev,
        { date: now.toISOString().split('T')[0], time: now.toTimeString().split(' ')[0] },
      ]);

      Swal.fire({
        icon: 'success',
        title: 'Updated!',
        text: `${updatedEmployee.firstName}'s data has been updated.`,
        showConfirmButton: false,
        timer: 1500,
      });

      setEmployees(employees);
      setIsEditing(false);
      getEmployees();
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const incrementValue = (key, incrementAmount) => {
    setGameValues((prev) => ({
      ...prev,
      [key]: prev[key] + incrementAmount,
    }));
  };

  const decrementValue = (key, decrementAmount) => {
    Swal.fire({
      title: 'Enter PIN to Decrease Value',
      input: 'password',
      inputAttributes: {
        maxlength: 4,
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        const pin = result.value;
        if (pin === '1551') {
          setGameValues((prev) => ({
            ...prev,
            [key]: Math.max(0, prev[key] - decrementAmount),
          }));
          Swal.fire('Success', 'Value decreased!', 'success');
        } else {
          Swal.fire('Error', 'Incorrect PIN!', 'error');
        }
      }
    });
  };

  const filteredGames = gameFields.filter(({ label }) =>
    label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container">
      <div className="header">
        <h2>Customer ID: {id}</h2>
        <h1>Update Customer Data</h1>
      </div>
      <div className="total-spent-bar">Total Spent: GH₵ {totalSpent}</div>
      <input
        type="text"
        placeholder="Search Game"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />
      <form onSubmit={handleUpdate}>
        {filteredGames.map(({ label, key, increment }) => (
          <div className="game-card" key={key}>
            <label>{label}</label>
            <input type="number" value={gameValues[key]} readOnly />
            <div className="button-group">
              <button type="button" onClick={() => incrementValue(key, increment)}>+</button>
              <button type="button" onClick={() => decrementValue(key, increment)}>-</button>
            </div>
          </div>
        ))}
        <div className="form-actions">
          <button type="submit" className="btn btn-success">Update</button>
          <button type="button" className="btn btn-cancel" onClick={() => setIsEditing(false)}>Cancel</button>
        </div>
      </form>

      {/* Inline CSS */}
      <style>
        {`
          .container {
            max-width: 900px;
            margin: 20px auto;
            padding: 20px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }
          .header {
            text-align: center;
            margin-bottom: 20px;
          }
          h1 {
            color: #333;
          }
          h2 {
            font-size: 18px;
            color: #555;
            margin-bottom: 10px;
          }
          .total-spent-bar {
            background: blue;
            border-radius: 6px;
            padding: 10px;
            margin-bottom: 20px;
            text-align: center;
            font-weight: bold;
            color:white;
          }
          .search-bar {
            width: 100%;
            padding: 12px;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-bottom: 20px;
            font-size: 16px;
          }
          .game-card {
            display: flex;
            align-items: center;
            background: #f9f9f9;
            border: 1px solid #ddd;
            border-radius: 6px;
            padding: 10px;
            margin-bottom: 15px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          }
          .game-card label {
            flex: 1;
            font-size: 16px;
            font-weight: bold;
            color: #555;
          }
          .game-card input {
            width: 70px;
            text-align: center;
            margin-right: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 5px;
          }
          .button-group button {
            margin-right: 5px;
            padding: 5px 10px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            color: #fff;
          }
          .button-group button:first-child {
            background-color: green;
          }
          .button-group button:last-child {
            background-color: red;
          }
          .form-actions {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          }
          .btn {
            padding: 10px 20px;
            border-radius: 4px;
            font-size: 16px;
            cursor: pointer;
          }
          .btn-success {
            background-color: #28a745;
            color: white;
            border: none;
          }
          .btn-cancel {
            background-color: #dc3545;
            color: white;
            border: none;
          }
          .btn:hover {
            opacity: 0.9;
          }
        `}
      </style>
    </div>
  );
};

export default Edit;
