import React from 'react';
import styled from 'styled-components';
import Logout from '../Logout';
import logo from '../../assets/GAMEITLOGO.png';

const Header = ({ setIsAdding, setIsAuthenticated }) => {
  return (
    <HeaderContainer>
      <Logo src={logo} alt="Arcade Logo" />
      <HeaderContent>
        <h1>Arcade Management Software</h1>
        <div className="button-container">
          <button onClick={() => setIsAdding(true)}>Add Customer Data</button>
          <Logout setIsAuthenticated={setIsAuthenticated} />
        </div>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;

// Styled Components

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6a1b9a; /* Purple background */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px;

  /* Logo responsiveness */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
`;

const Logo = styled.img`
  height: 60px; /* Adjust the size of the logo */
  margin-right: 20px;

  /* Logo responsiveness */
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin: 0;
    color:white;
    font-weight: 700;
  }

  .button-container {
    margin-top: 20px;
    display: flex;
    gap: 15px;
    justify-content: center;

    button {
      padding: 10px 20px;
      font-size: 1rem;
      background-color: white;
      color: #6a1b9a;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  /* Button responsiveness */
  @media (max-width: 768px) {
    .button-container {
      flex-direction: column;
      gap: 10px;
    }
  }
`;
