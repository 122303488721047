import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { collection, setDoc, doc } from "firebase/firestore";
import { db } from "../../../src/config/firestore";
import styled from "styled-components";

const Add = ({ employees, setEmployees, setIsAdding, getEmployees, onChartDataChange }) => {
  const [firstName, setFirstName] = useState("");
  const [date, setDate] = useState("");
  const [totalSpent, setTotalSpent] = useState(0);

  // Removed game fields and prices

  const [games, setGames] = useState({});

  useEffect(() => {
    const total = Object.keys(games).reduce(
      (sum, game) => sum + games[game] * 0, // No game prices anymore
      0
    );
    setTotalSpent(total);
  }, [games]);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!firstName || !date) {
      return Swal.fire({
        icon: "error",
        title: "Error!",
        text: "All required fields must be filled.",
        showConfirmButton: true,
      });
    }

    const newEmployee = {
      firstName,
      date,
      games: { ...games },
      totalSpent,
    };

    const customId = `gameit-${Math.random().toString(36).substr(2, 3)}`;

    try {
      await setDoc(doc(collection(db, "employees"), customId), { ...newEmployee });
      console.log("Document written with custom ID: ", customId);
    } catch (error) {
      console.error("Error writing document: ", error);
    }

    setEmployees([...employees, newEmployee]);
    setIsAdding(false);
    getEmployees();

    Swal.fire({
      icon: "success",
      title: "Added!",
      text: `${firstName}'s data has been added.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <AddContainer>
      <div className="cancel-button">
        <button onClick={() => setIsAdding(false)}>Cancel</button>
      </div>

      <StickyTotalSpentCard>
        Total Amount: <span className="amount">GHS {totalSpent.toFixed(2)}</span>
      </StickyTotalSpentCard>

      <form onSubmit={handleAdd}>
        <h1>Add Customer Data</h1>

        <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />

        <label htmlFor="date">Date</label>
        <input
          id="date"
          type="date"
          onChange={(e) => setDate(`${e.target.value} ${new Date().toLocaleTimeString()}`)}
          required
        />

        <button type="submit">Add</button>
      </form>
    </AddContainer>
  );
};

// Styled components
const AddContainer = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  .cancel-button {
    text-align: right;
    margin-bottom: 10px;

    button {
      padding: 8px 12px;
      border: none;
      background: #ff4d4f;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #e03a3e;
      }
    }
  }

  form {
    max-width: 800px;
    margin: auto;

    h1 {
      text-align: center;
      margin-bottom: 20px;
    }

    label {
      display: block;
      margin-bottom: 8px;
      font-weight: bold;
    }

    input {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    button {
      width: 100%;
      padding: 12px;
      background-color: #007bff;
      color: white;
      font-weight: bold;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
`;

const StickyTotalSpentCard = styled.div`
  position: sticky;
  top: 0;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;

  .amount {
    font-weight: bold;
  }
`;

export default Add;
