import React, { useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../src/config/firestore';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/GAMEITLOGO.png';

const ViewEmployee = () => {
    const [customId, setCustomId] = useState('');
    const [employeeData, setEmployeeData] = useState(null);
    const [isPaid, setIsPaid] = useState(false);

    const handleSearch = async (e) => {
        e.preventDefault();

        if (!customId) {
            return Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please enter a custom ID.',
                showConfirmButton: true,
            });
        }

        try {
            const docRef = doc(db, "employees", customId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setEmployeeData(data);
                setIsPaid(data.status === 'Paid');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Not Found!',
                    text: 'No customer found with the given ID.',
                    showConfirmButton: true,
                });
                setEmployeeData(null);
            }
        } catch (error) {
            console.error("Error fetching employee: ", error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Something went wrong while fetching the employee data.',
                showConfirmButton: true,
            });
        }
    };

    const handleStatusChange = async () => {
        const { value: pin } = await Swal.fire({
            title: 'Enter PIN to Change Status',
            input: 'password',
            inputLabel: 'PIN',
            inputPlaceholder: 'Enter PIN',
            showCancelButton: true,
        });

        if (pin === '1551') {
            try {
                await updateDoc(doc(db, "employees", customId), {
                    status: isPaid ? 'Unpaid' : 'Paid',
                });
                setIsPaid(!isPaid);
                Swal.fire({
                    icon: 'success',
                    title: 'Status Updated',
                    text: `Customer status changed to ${isPaid ? 'Unpaid' : 'Paid'}.`,
                });
            } catch (error) {
                console.error("Error updating status: ", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Could not update status.',
                });
            }
        } else if (pin) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid PIN',
                text: 'You entered an incorrect PIN.',
            });
        }
    };

    const generatePDF = () => {
        if (!employeeData) return;

        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [80, 200],
        });

        doc.addImage(logo, 'PNG', 10, 10, 20, 20);
        doc.setFontSize(10);
        doc.text("Let's Game It", 35, 15);
        doc.text("Center Point Mall", 35, 20);
        doc.text("Accra, Ghana", 35, 25);
        doc.text("Tel: +233245603958", 35, 30);

        doc.setFontSize(12);
        doc.text("Receipt", 25, 40);
        doc.setFontSize(10);
        doc.text(`Customer ID: ${employeeData.id}`, 10, 50);
        doc.text(`Date: ${new Date().toLocaleString()}`, 10, 55);

        const filteredServices = getFilteredServices();
        const tableData = filteredServices.map(service => [service.name, `${service.cost} GHS`]);

        doc.autoTable({
            head: [['Game Item', 'Cost (GHS)']],
            body: tableData,
            startY: 60,
            theme: 'grid',
            styles: { fontSize: 8, cellPadding: 2 },
            columnStyles: { 0: { cellWidth: 50 }, 1: { cellWidth: 30 } },
        });

        const totalSpent = employeeData.totalSpent.toFixed(2);
        doc.text(`Total Due: ${totalSpent} GHS`, 10, doc.previousAutoTable.finalY + 10);
        doc.text(`Status: ${isPaid ? 'Paid' : 'Unpaid'}`, 10, doc.previousAutoTable.finalY + 15);

        doc.text("Thank you for choosing Let's Game It!", 10, doc.previousAutoTable.finalY + 25, { maxWidth: 70 });

        doc.save('Customer_Receipt.pdf');
    };

    const getFilteredServices = () => {
        return [
            { name: 'Video Games', cost: employeeData?.videoGames },
            { name: 'Basketball', cost: employeeData?.basketball },
            { name: 'Socks', cost: employeeData?.socks },
            { name: 'Connect Ball', cost: employeeData?.connectball },
            { name: 'Jump It', cost: employeeData?.jumpIt },
            { name: 'Hammer', cost: employeeData?.hammer },
            { name: 'Just Dance', cost: employeeData?.justDance },
            { name: 'Racing', cost: employeeData?.racing},
            { name: 'Hitbeans', cost: employeeData?.hitBeans },
            { name: 'Claw Machine', cost: employeeData?.clawMachine },
            { name: 'Virtual Reality', cost: employeeData?.vr },
            { name: 'F1', cost: employeeData?.f1 },
            { name: 'Aliens', cost: employeeData?.aliens },
            { name: 'Karaoke', cost: employeeData?.karaoke },
            { name: 'Snocross', cost: employeeData?.snowcross },
            { name: 'Jurassic Park', cost: employeeData?.jurassicPark },
            { name: 'Go-Karts', cost: employeeData?.goKart },
            { name: 'Laser Tag', cost: employeeData?.laserTag },
            { name: 'Football Snooker', cost: employeeData?.footballSnooker },
        ].filter(service => service.cost > 0);
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Check Amount Due</h1>
            <img src={logo} alt="Arcade Logo" style={styles.logo} />

            <form onSubmit={handleSearch} style={styles.form}>
                <label htmlFor="customId" style={styles.label}>Enter Customer ID</label>
                <input
                    id="customId"
                    type="text"
                    value={customId}
                    onChange={e => setCustomId(e.target.value)}
                    required
                    style={styles.input}
                    placeholder="e.g., gameit-1234"
                />
                <input type="submit" value="Search" style={styles.submitButton} />
            </form>

            {employeeData && (
                <div style={styles.invoice}>
                    <h2 style={styles.invoiceHeader}>Receipt Details</h2>
                    <p><strong>Customer ID:</strong> {employeeData.id}</p>
                    <p><strong>Name:</strong> {employeeData.firstName} {employeeData.lastName}</p>
                    <p><strong>Date:</strong> {new Date().toLocaleString()}</p>

                    <div style={styles.statusSection}>
                        <label>Status: </label>
                        <button onClick={handleStatusChange} style={{ ...styles.statusToggle, backgroundColor: isPaid ? '#4CAF50' : '#FF5722' }}>
                            {isPaid ? 'Paid' : 'Unpaid'}
                        </button>
                    </div>

                    <div style={styles.tableContainer}>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.tableHeader}>Games Played</th>
                                    <th style={styles.tableHeader}>Cost (GHS)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getFilteredServices().map((service, index) => (
                                    <tr key={index}>
                                        <td style={styles.tableCell}>{service.name}</td>
                                        <td style={styles.tableCell}>{service.cost}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div style={styles.totalSection}>
                        <h3>Total Due: <span style={styles.totalAmount}>GH₵ {employeeData.totalSpent.toFixed(2)} </span></h3>
                        <button onClick={generatePDF} style={styles.printButton}>Generate Receipt</button>
                    </div>

                    <p style={styles.thankYou}>Thank you for choosing Let's Game It!</p>
                </div>
            )}
        </div>
    );
};

const styles = {

  
    container: {
        maxWidth: '600px',
        margin: 'auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#FAFAFA',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        minHeight: '100vh',
    },
    heading: {
        fontSize: '24px',
        textAlign: 'center',
        color: '#3F51B5',
        marginBottom: '20px',
    },
    logo: {
        display: 'block',
        margin: '0 auto',
        width: '80px',
        height: 'auto',
    },
    form: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        marginBottom: '8px',
        color: '#555',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        marginBottom: '10px',
        width: '100%',
        boxSizing: 'border-box',
    },
    submitButton: {
        padding: '10px 20px',
        backgroundColor: '#3F51B5',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    invoice: {
        backgroundColor: '#fff',
        padding: '15px',
        borderRadius: '8px',
        marginTop: '20px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    },
    invoiceHeader: {
        fontSize: '20px',
        textAlign: 'center',
        color: '#3F51B5',
        marginBottom: '15px',
    },
    statusSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '15px',
    },
    statusToggle: {
        padding: '5px 10px',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginLeft: '10px',
    },
    tableContainer: {
        marginTop: '10px',
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    tableHeader: {
        backgroundColor: '#3F51B5',
        color: 'white',
        padding: '8px',
    },
    tableCell: {
        padding: '8px',
        borderBottom: '1px solid #ddd',
    },
    totalSection: {
        marginTop: '20px',
        textAlign: 'center',
    },
    totalAmount: {
        color: '#FF5722',
        fontWeight: 'bold',
    },
    printButton: {
        marginTop: '15px',
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    thankYou: {
        textAlign: 'center',
        fontSize: '14px',
        marginTop: '20px',
        color: '#777',
    },
};

export default ViewEmployee;
